@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
* {
    font-family: 'Poppins', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


